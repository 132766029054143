<template>
  <div v-if="permission.access">
    <div class="row">
      <div class="col-md-8">
        <b-card title="Background List" no-body>
          <b-card-body>
            <b-row class="justify-content-between">
              <b-col
                cols="12"
                md="1"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <Select
                  @on-change="paginateChange"
                  v-model="search.paginate"
                  :clearable="false"
                >
                  <Option :value="10"> 10 </Option>
                  <Option :value="30"> 30 </Option>
                  <Option :value="50"> 50 </Option>
                  <Option :value="80"> 80 </Option>
                  <Option :value="100"> 100 </Option>
                </Select>
              </b-col>
              <b-col cols="12" md="3">
                <Select
                  v-model="search.is_required"
                  @on-change="SearchData"
                  :clearable="true"
                  placeholder="Select Required"
                >
                  <Option :value="1"> Required </Option>
                  <Option :value="2"> Not Required </Option>
                </Select>
              </b-col>
              <b-col cols="12" md="3">
                <Select
                  v-model="search.is_unique"
                  @on-change="SearchData"
                  :clearable="true"
                  placeholder="Select uniquness"
                >
                  <Option :value="1"> Unique </Option>
                  <Option :value="2"> Common </Option>
                </Select>
              </b-col>
              <b-col cols="12" md="3">
                <Select
                  v-model="search.data_type"
                  @on-change="SearchData"
                  :clearable="true"
                  placeholder="Select Data type"
                >
                  <Option value="string"> String </Option>
                  <Option value="number"> Number </Option>
                  <Option value="date"> Date </Option>
                  <Option value="image"> Image </Option>
                  <Option value="text"> Text </Option>
                </Select>
              </b-col>
              <b-col cols="12" md="2">
                <Input
                  v-model="search.search_data"
                  class="d-inline-block mr-1"
                  placeholder="Search name..."
                  type="text"
                />
              </b-col>
            </b-row>
          </b-card-body>
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th class="text-center">Sl</th>
                  <th>Name</th>
                  <th class="text-center">Requrements</th>
                  <th class="text-center">Data Type</th>
                  <th
                    class="text-center"
                    v-if="permission.edit || permission.delete"
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(meta, index) in metaDatas.data" :key="index">
                  <td class="align-middle text-center">
                    {{ metaDatas.from + index }}
                  </td>
                  <td width="150">
                    <p class="mb-0">
                      <b>{{ meta.name }}</b>
                    </p>
                  </td>
                  <td class="align-middle text-center">
                    <span
                      class="badge"
                      :class="
                        meta.is_required == 1 ? 'badge-success' : 'badge-info'
                      "
                      style="margin-right: 2px"
                    >
                      {{ meta.is_required == 1 ? "Required" : "Nullable" }}
                    </span>
                    <span
                      class="badge"
                      :class="
                        meta.is_unique == 1
                          ? 'badge-primary'
                          : 'badge-secondary'
                      "
                    >
                      {{ meta.is_unique == 1 ? "Unique" : "Common" }}
                    </span>
                  </td>
                  <td class="align-middle text-center">
                    <p>
                      <span
                        class="badge"
                        :class="
                          meta.data_type == 'string'
                            ? 'badge-primary'
                            : 'badge-info'
                        "
                        style="margin-right: 2px"
                      >
                        {{ meta.data_type }}
                      </span>
                      <span
                        class="badge"
                        :class="
                          meta.is_unique == 1
                            ? 'badge-primary'
                            : 'badge-secondary'
                        "
                      >
                        {{
                          meta.is_required == 1
                            ? "Meta Data"
                            : meta.is_required == 2
                            ? "Text Paragraph"
                            : "Fixed Data"
                        }}
                      </span>
                    </p>
                  </td>
                  <td
                    class="align-middle text-center"
                    v-if="permission.edit || permission.delete"
                  >
                    <button
                      class="btn btn-primary btn-sm"
                      @click="Edit(meta.id)"
                      v-if="permission.edit"
                    >
                      <feather-icon icon="EditIcon" size="16" />
                    </button>
                    <button
                      class="btn btn-danger btn-sm"
                      style="margin-left: 5px"
                      @click="statusUpdate(meta.id)"
                      v-if="permission.delete"
                    >
                      <feather-icon icon="TrashIcon" size="16" />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <b-card-body>
            <div class="d-flex justify-content-between align-items-center">
              <span class="text-muted"
                >Showing {{ metaDatas.from }} to {{ metaDatas.to }} of
                {{ metaDatas.total }} entries</span
              >
              <pagination
                :data="metaDatas"
                @pagination-change-page="getResults"
                align="right"
                :show-disabled="true"
                :limit="5"
              ></pagination>
            </div>
          </b-card-body>
        </b-card>
      </div>
      <div class="col-md-4" v-if="permission.create || form.id != null">
        <b-card :title="'Meta Data ' + (!form.id ? 'Add' : 'Update')">
          <b-form @submit.prevent="!form.id ? Create() : Update(form.id)">
            <b-row class="justify-content-center">
              <b-col cols="12">
                <div class="form-group">
                  <label> Name* </label>
                  <Input
                    v-model="form.name"
                    placeholder="Enter Name"
                    style="width: 100%"
                    type="text"
                  />
                  <has-error :form="form" field="name"></has-error>
                </div>

                <div class="form-group">
                  <label for="seniority">Data Type*</label>
                  <Select style="margin-right: 10px" v-model="form.data_type">
                    <Option value="string"> String </Option>
                    <Option value="integer"> Integer </Option>
                    <Option value="date"> Date </Option>
                    <Option value="image"> Image </Option>
                    <Option value="text"> Text </Option>
                  </Select>
                  <has-error :form="form" field="data_type"></has-error>
                </div>
                <div class="form-group">
                  <label for="seniority">Type*</label>
                  <Select style="margin-right: 10px" v-model="form.type">
                    <Option :value="1"> Meta Data </Option>
                    <Option :value="2"> Text Paragraph </Option>
                    <Option :value="3"> Fixed Data </Option>
                  </Select>
                  <has-error :form="form" field="type"></has-error>
                </div>
                <div class="form-group" v-if="form.type == 2">
                  <label> Default Data </label>
                  <Input
                    v-model="form.default_data"
                    placeholder="Enter Default Data"
                    type="textarea"
                    style="width: 100%"
                    :rows="3"
                  />
                  <has-error :form="form" field="default_data"></has-error>
                </div>
                <div v-else>
                  <label> Default Data </label>
                  <Input
                    v-model="form.default_data"
                    placeholder="Enter Default Data"
                    style="width: 100%"
                    type="text"
                  />
                  <has-error :form="form" field="default_data"></has-error>
                </div>
                <div class="d-flex">
                  <div class="form-group text-right">
                    <label for="negotiation"> Is Required* &nbsp;</label>
                    <b-form-checkbox
                      name="status"
                      style="margin-top: 10px; padding-top: 4px"
                      v-model="form.is_required"
                      switch
                      inline
                      id="negotiation"
                    >
                    </b-form-checkbox>
                    <has-error :form="form" field="negotiable"></has-error>
                  </div>
                  <div class="form-group text-right">
                    <label for="unique"> Is unique* &nbsp;</label>
                    <b-form-checkbox
                      name="status"
                      style="margin-top: 10px; padding-top: 4px"
                      v-model="form.is_unique"
                      switch
                      inline
                      id="unique"
                    >
                    </b-form-checkbox>
                    <has-error :form="form" field="negotiable"></has-error>
                  </div>
                </div>
              </b-col>

              <b-col cols="12">
                <div class="d-flex justify-content-end align-items-center mt-2">
                  <div>
                    <b-button
                      variant="transparent"
                      class="border text-danger"
                      @click="Clear"
                    >
                      Clear
                    </b-button>
                    <b-button
                      variant="primary"
                      type="submit"
                      :disabled="form.busy"
                      class="ml-1"
                    >
                      {{ form.id ? "Update" : "Create" }}
                    </b-button>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BFormCheckbox,
  BForm,
  BButton,
  BFormFile,
} from "bootstrap-vue";
import { HasError } from "vform/src/components/bootstrap5";
import _ from "lodash";

export default {
  data() {
    return {
      form: new Form({
        id: null,
        name: null,
        default_data: null,
        data_type: null,
        type: null,
        is_required: true,
        is_unique: false,
      }),
      search: {
        search_data: "",
        is_required: "",
        is_unique: "",
        data_type: "",
        paginate: 10,
        page: 1,
      },
      metaDatas: {},
    };
  },
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BFormCheckbox,
    BForm,
    BButton,
    HasError,
    BFormFile,
  },
  mounted() {
    this.getResults();
  },
  methods: {
    getResults(page) {
      this.search.page = page == null ? this.search.page : page;
      const queryParams = this.buildQueryParamString(this.search);
      axios
        .get(`/app/certificate/meta-data${queryParams}`)
        .then((res) => {
          this.metaDatas = res.data.meta_data;
        })
        .catch((e) => {
          this.e(e.response.data.message);
        });
    },
    Clear() {
      this.form.id = null;
      this.form.name = null;
      this.form.default_data = null;
      this.form.data_type = null;
      this.form.type = null;
      this.form.is_required = true;
      this.form.is_unique = false;
    },

    Create() {
      this.form
        .post("/app/certificate/meta-data")
        .then((res) => {
          this.getResults();
          this.Clear();
          this.s(res.data.message);
        })
        .catch((e) => {
          this.e(e.response.data.message);
        });
    },
    Update(id) {
      this.form
        .put("/app/certificate/meta-data/" + id)
        .then((res) => {
          this.getResults();
          this.Clear();
          this.s(res.data.message);
        })
        .catch((e) => {
          this.e(e.response.data.message);
        });
    },

    Edit(id) {
      axios
        .get("/app/certificate/meta-data/" + id)
        .then((res) => {
          this.form.id = res.data.meta_data.id;
          this.form.name = res.data.meta_data.name;
          this.form.default_data = res.data.meta_data.default_data;
          this.form.data_type = res.data.meta_data.data_type;
          this.form.type = res.data.meta_data.type;
          this.form.is_required =
            res.data.meta_data.is_required == 1 ? true : false;
          this.form.is_unique =
            res.data.meta_data.is_unique == 1 ? true : false;
        })
        .catch((e) => {
          this.e(e.response.data.message);
        });
    },
    statusUpdate(id) {
      Swal.fire({
        title: "Are you sure?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete("/app/certificate/meta-data/" + id)
            .then((res) => {
              this.getResults();
              this.Clear();
              this.s(res.data.message);
            })
            .catch((e) => {
              this.e(e.response.data.message);
            });
        }
      });
    },
    SearchData: _.debounce(function () {
      this.search.page = 1;
      this.getResults();
    }, 1000),
    paginateChange() {
      this.search.page = 1;
      this.getResults();
    },
  },
  computed: {
    permission() {
      return this.$store.getters.getPermissions.metadata;
    },
  },
  watch: {
    "search.search_data": function () {
      this.SearchData();
    },
    "search.paginate": function () {
      this.paginateChange();
    },
  },
};
</script>
